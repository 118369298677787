export default [
    {
        name: "회원관리",
        icon: "users",
        to: "/master/main"
    },
    {
        name: "게시물 관리",
        icon: "chalkboard",
        to: "/master/post"
    },
    {
        name: "결제 관리",
        icon: "receipt",
        to: "/master/payment"
    },
    {
        name: "내 정보",
        icon: "user",
        to: "/master/myInfo"
    },
];
